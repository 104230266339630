<template>
    <div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 30px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-row justify-content-center flex-wrap me-3"
                style="justify-content: space-between !important;width: 100%;">
                <!--begin::Title-->
                <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0" style="margin-right: 20px;">
                    Hi Admin {{ this.dataUser.name }}, Wellcome!
                </h1>
                <div v-if="this.isAdmin == true" class="d-flex align-items-center gap-2 gap-lg-3">
                    <!--begin::Secondary button-->
                    <router-link  class="btn btn-sm fw-bold btn-secondary " to="/">
                    DashBoard User </router-link>
                <!--end::Secondary button-->
                
                <!--begin::Primary button-->
                    <router-link to="/dashBoardAdmin" class="btn btn-sm fw-bold btn-primary" >
                    DashBoard Admin</router-link>
                <!--end::Primary button-->
            </div>
                <!--end::Title-->
                <div class="dropdown-container">
                    <div class=" btn btn-light-primary dropdown" style="margin-right: 20px;margin-top: 20px;">
                        <a>{{ selectedOption }}</a>
                        <div class="dropdown-menu">
                            <a v-for="option in dropdownOptions" :key="option" @click="selectOption(option)">{{
                                option }}</a>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-start" data-kt-customer-table-toolbar="base"
                    style="height: 50px;margin-top: 15px;">
                    <!--begin::Filter-->
                    <button @click="toggleMenuFilter" type="button" class="btn btn-light-primary me-3 "
                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <i class="ki-duotone ki-filter fs-2">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>{{ this.$NOLIMITResource[this.dataUser.language].FilterSearch }}</button>
                    <!--begin::Menu 1-->
                    <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px  show-dropfilter"
                        :class="{ show: this.onShowMenuFilter == true }" data-kt-menu="true" id="kt-toolbar-filter"
                        data-select2-id="select2-data-kt-toolbar-filter" style="">
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                            <div class="fs-4 text-gray-900 fw-bold">{{
                                this.$NOLIMITResource[this.dataUser.language].FilterOptions }}</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Separator-->
                        <form action="" @submit.prevent="onFilterSearch">
                            <!--begin::Content-->
                            <div class="px-7 py-5" data-select2-id="select2-data-128-13oj">
                                <!--begin::Input group-->
                                <div>
                                    <label class="form-label fs-5 fw-semibold mb-3">{{
                                        this.$NOLIMITResource[this.dataUser.language].StartDate }}:</label>
                                    <v-date-picker class="inline-block h-full" v-model="dateStart"
                                        :timezone="timezone2">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <input @click="togglePopover()" :value="inputValue"
                                                    class="form-control form-control-solid flatpickr-input active"
                                                    name="calendar_event_end_date"
                                                    :placeholder="this.$NOLIMITResource[this.dataUser.language].StartDate"
                                                    id="kt_calendar_datepicker_end_date" type="text" readonly="readonly"
                                                    style="margin-bottom:20px">
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>
                                <!--end::Input group-->

                                <!--begin::Input group-->
                                <div>
                                    <label class="form-label fs-5 fw-semibold mb-3">{{
                                        this.$NOLIMITResource[this.dataUser.language].EndDate }}:</label>
                                    <v-date-picker class="inline-block h-full" v-model="dateEnd" :timezone="timezone1">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <input @click="togglePopover()" :value="inputValue"
                                                    class="form-control form-control-solid flatpickr-input active"
                                                    name="calendar_event_end_date"
                                                    :placeholder="this.$NOLIMITResource[this.dataUser.language].EndDate"
                                                    type="text" readonly="readonly" style="margin-bottom:20px">
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>
                                <!--end::Input group-->


                                <!--begin::Actions-->
                                <div class="d-flex justify-content-end">
                                    <button @click="onResetFilter" type="reset"
                                        class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true"
                                        data-kt-customer-table-filter="reset">{{
                                            this.$NOLIMITResource[this.dataUser.language].Reset }}</button>
                                    <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                                        data-kt-customer-table-filter="filter">{{
                                            this.$NOLIMITResource[this.dataUser.language].Filter }}</button>
                                </div>
                                <!--end::Actions-->
                            </div>
                            <!--end::Content-->
                        </form>
                    </div>
                    <!--end::Menu 1-->
                    <!--end::Filter-->
                </div>
            </div>
            <!--end::Page title-->
        </div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 5px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <p style="font-size: 15px !important;"
                    class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Thống kê ví
                </p>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <div class="row g-5 g-xl-8" style="padding:0 30px">
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-danger hoverable card-xl-stretch mb-xl-8"
                    style="background: linear-gradient(90deg, #00C897, #c8ebd9);">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i class="ki-duotone ki-cheque text-white fs-2x ms-n1"><span class="path1"></span><span
                                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                    class="path5"></span><span class="path6"></span><span class="path7"></span></i>
                            <span class="text-white fw-bold fs-2 mb-2 mt-5"> Tổng nạp website</span>
                        </div>
                        <div class="text-white fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalDeposit) }} <span class="fw-semibold text-white"
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>

            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-primary hoverable card-xl-stretch mb-xl-8"
                    style="background: linear-gradient(90deg, #65b2ef, #aad1eb);">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i class="ki-duotone ki-cheque text-white fs-2x ms-n1"><span class="path1"></span><span
                                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                    class="path5"></span><span class="path6"></span><span class="path7"></span></i>
                            <span class="text-white fw-bold fs-2 mb-2 mt-5"> Tổng topup</span>
                        </div>
                        <div class="text-white fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalTopup) }} <span class="fw-semibold text-white"
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background: linear-gradient(90deg, #735cdb, #81a1e5);padding-bottom:0">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i class="ki-duotone ki-cheque text-white fs-2x ms-n1"><span class="path1"></span><span
                                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                    class="path5"></span><span class="path6"></span><span class="path7"></span></i>
                            <span class="text-white fw-bold fs-2 mb-2 mt-5"> Tổng số dư trên ví</span>
                        </div>
                        <div class="col-xl-12" style="display:flex">
                            <div class="col-xl-6" style="margin-right:5px">

                                <!--begin::Statistics Widget 5-->
                                <div class="card bg-body hoverable card-xl-stretch mb-xl-8" style="padding:5px 5px">
                                    <!--begin::Body-->
                                    <div class="card-body-top">
                                        <span class="fw-semibold text-black" style="font-size:12px">Tổng số dư
                                            Admin</span>

                                        <div class="text-black fw-bold fs-2 ">
                                            {{ formatCurrency(this.balanceSummary.balanceAdmin) }} <span
                                                class="fw-semibold text-black" style="font-size:10px">VND</span>
                                        </div>
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Statistics Widget 5-->
                            </div>
                            <div class="col-xl-6">

                                <!--begin::Statistics Widget 5-->
                                <div class="card bg-body hoverable card-xl-stretch mb-xl-8" style="padding:5px 5px">
                                    <!--begin::Body-->
                                    <div class="card-body-top">
                                        <span class="fw-semibold text-black" style="font-size:12px">Tổng số dư
                                            User</span>

                                        <div class="text-black fw-bold fs-2 ">
                                            {{ formatCurrency(this.balanceSummary.balanceUser) }} <span
                                                class="fw-semibold text-black" style="font-size:10px">VND</span>
                                        </div>
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Statistics Widget 5-->
                            </div>
                        </div>
                    </div>

                    <!--end::Body-->
                </a>

                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background: linear-gradient(90deg, #00C897, #c8ebd9);">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i class="ki-duotone ki-cheque text-white fs-2x ms-n1"><span class="path1"></span><span
                                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                    class="path5"></span><span class="path6"></span><span class="path7"></span></i>
                            <span class="text-white fw-bold fs-2 mb-2 mt-5"> Doanh thu website</span>
                        </div>
                        <div class="text-white fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalFeeTopup) }} <span class="fw-semibold text-white"
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
        </div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 5px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <p style="font-size: 15px !important;"
                    class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Báo cáo hệ thống
                </p>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <div class="row g-5 g-xl-8" style="padding:0 30px">
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền đã chi tiêu</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalTopupAllTime) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền nạp qr code</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalDepositQR) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền admin cộng tiền</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalDepositPlusMoney) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền admin trừ tiền</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalMinusMoney) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền hoàn</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalRefundMoney) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền chuyển đi</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalDepositTransfer) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền user nhận được</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalReceiveMoney) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tiền user nhận từ Admin</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalReceiveMoneyAdmin) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
        </div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 5px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <p style="font-size: 15px !important;"
                    class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Thống kê tài khoản ads
                </p>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <div class="row g-5 g-xl-8" style="padding:0 30px">

            <div class="col-xl-4">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số dư thực tế trên tài khoản</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalRemainingAmountAds) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-4">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Giới hạn chi tiêu thực tế </span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ formatCurrency(this.summary.totalSpencapAds) }} <span class="fw-semibold "
                                style="font-size:10px">VND</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>

            <div class="col-xl-4">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Số tài khoản trên hệ thống</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ this.summary.activeAdsAccounts }}/{{ this.summary.inactiveAdsAccounts }} <span
                                class="fw-semibold " style="font-size:10px">Live/Die</span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>

        </div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 5px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <p style="font-size: 15px !important;"
                    class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Thống kê hoá đơn
                </p>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <div class="row g-5 g-xl-8" style="padding:0 30px">
            <div class="col-xl-6">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-danger hoverable card-xl-stretch mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Hoá đơn đã thanh toán</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ this.summary.countBillPaid }} <span class="fw-semibold " style="font-size:10px"></span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>

            <div class="col-xl-6">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-primary hoverable card-xl-stretch mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#65b2ef" class="ki-duotone ki-cheque  fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> Giao dịch không xác định</span>
                        </div>
                        <div class=" fw-bold fs-2 mb-2 mt-5" style="margin-top: 31px !important;">
                            {{ this.summary.countBillUnpaid }} <span class="fw-semibold " style="font-size:10px"></span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
        </div>
        <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack"
            style="margin-left: 10px !important; margin-bottom: 5px">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <!--begin::Title-->
                <p style="font-size: 15px !important;"
                    class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                    Thống kê theo nhóm doanh nghiệp
                </p>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <div class="row g-5 g-xl-8" style="padding:0 30px" >
            <div class="col-xl-2" v-for="([businessName, spencap], index) in Object.entries(spencapByBusiness)" :key="index">

                <!--begin::Statistics Widget 5-->
                <a  class="card bg-danger hoverable card-xl-stretch mb-xl-8"
                    style="background-color: #fff !important;color:#888">
                    <!--begin::Body-->
                    <div class="card-body card-body-top">
                        <div>
                            <i style="color:#00C897" class="ki-duotone ki-cheque fs-2x ms-n1"><span
                                    class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                    class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                    class="path7"></span></i>
                            <span class=" fw-bold fs-2 mb-2 mt-5"> {{   }} {{ businessName  }} </span>
                        </div>
                        <div class="row g-0" style="width: 100%;">
                <!--begin::Col-->
                <div class="col-xl-6 mr-8">
                    <!--begin::Label-->
                    <div class="fs-7 text-muted fw-bold">VND</div>
                    <!--end::Label-->

                    <!--begin::Stat-->
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="fs-4 fw-bold">{{ formatCurrency(spencap.totalSpencapVND)  }} </div>
                    </div>
                    <!--end::Stat-->
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col">
                    <!--begin::Label-->
                    <div class="fs-7 text-muted fw-bold">USD</div>
                    <!--end::Label-->

                    <!--begin::Stat-->
                    <div class="fs-4 fw-bold">{{ formatCurrency(spencap.totalSpencapUSD)  }} </div>
                    <!--end::Stat-->
                </div>
                <!--end::Col-->
            </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
        </div>
        <div>
            <div class="col-xxl-6 mb-md-5 mb-xl-10" style="width: 100%; padding: 30px">
                <div class="card-container" style="display:none">
                    <div class="card card-blue">
                        <div class="card-more"></div>
                        <div class="card-icon">
                            <i class="ki-duotone ki-dollar">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>
                        <div class="card-content">
                            <div class="amount">+ {{ formatCurrency(totalRechargeToday) }} đ <span
                                    style="font-size:12px;color:#22d770">{{
                                        this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
                            <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_One
                                }}
                            </div>
                            <div class="total">{{ formatCurrency(balanceUser) }}đ / {{ formatCurrency(totalRecharge) }}đ
                            </div>
                        </div>
                    </div>
                    <div class="card card-red">
                        <div class="card-more"></div>
                        <div class="card-icon">
                            <i class="ki-duotone ki-dollar">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>
                        <div class="card-content">
                            <div class="amount">+ {{ formatCurrency(totalTopupAmountToday) }} đ <span
                                    style="font-size:12px;color:#22d770">{{
                                        this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
                            <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Two
                                }}
                            </div>
                            <div class="total">{{ formatCurrency(totalTopupAmount) }}đ</div>
                        </div>
                    </div>
                    <div class="card card-orange">
                        <div class="card-more"></div>
                        <div class="card-icon">
                            <i class="ki-duotone ki-dollar">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>
                        <div class="card-content">
                            <div class="amount"> {{ formatCurrency(totalReceiveMoneyToday) }}đ / {{
                                formatCurrency(totalTransferMoneyToday) }}đ <span
                                    style="font-size:12px;color:#22d770">{{
                                        this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span></div>
                            <div class="description">{{
                                this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Three }}
                            </div>
                            <div class="total">{{ formatCurrency(totalReceiveMoney) }}đ / {{
                                formatCurrency(totalTransferMoney)
                                }}đ</div>
                        </div>
                    </div>
                    <div class="card card-green">
                        <div class="card-more"></div>
                        <div class="card-icon">
                            <i class="ki-duotone ki-dollar">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>
                        <div class="card-content">
                            <div class="amount"> {{ formatCurrency(totalAdminPlusToday) }}đ / {{
                                formatCurrency(totalAdminMinusToday) }}đ <span style="font-size:12px;color:#22d770">{{
                                    this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
                            <div class="description">{{
                                this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Four }}
                            </div>
                            <div class="total">{{ formatCurrency(totalAdminPlus) }}đ / {{
                                formatCurrency(totalAdminMinus) }}đ
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-2-apexchart">
                    <div class="apex-chart-block">
                        <div class="header-apex-chart">
                            <p style="text-align: start; margin-left: 20px;text-transform:uppercase;font-size: 15px;color: #071437;"
                                data-v-ffde663e="" class="card-label fw-bold text-gray-900">
                                {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Overview }}: {{
                                    this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge }} ,{{
                                    this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup }}
                            </p>
                            <div class="legend-container">
                                <div class="legend-item">
                                    <div class="legend-color color-napvi"></div>
                                    <span>{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge }}</span>
                                </div>
                                <div class="legend-item">
                                    <div class="legend-color color-topup"></div>
                                    <span>{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup }}</span>
                                </div>
                                <!-- <div class="legend-item">
                    <div class="legend-color color-tkgiao"></div>
                    <span>Tk giao</span>
                  </div> -->

                            </div>
                        </div>

                        <div ref="realtimeChart" id="kt_apexcharts_3" class="chart-container"></div>
                    </div>
                    <div class="card-user" v-if="this.isAdmin != true && this.isSupport != true" style="padding:0">
                        <div class="card bg-primary h-md-100 bg-recharge" data-bs-theme="light"
                            style="width:100%;padding:0">
                            <!--begin::Body-->
                            <div class="card-body d-flex flex-column pt-13 pb-14">
                                <!--begin::Heading-->
                                <div class="m-0" style="    height: 400px;">
                                    <!--begin::Title-->
                                    <h1 class="fw-semibold text-white text-center lh-lg mb-9">
                                        Tạo hóa đơn nạp tiền thật nhanh chóng
                                    </h1>
                                    <div class="fs-4 text-white">
                                        <span class="opacity-75">Tạo hóa đơn ngay </span>
                                        <span class="position-relative d-inline-block">
                                            <a class="link-white opacity-75-hover fw-bold d-block mb-1">hoặc</a>
                                            <!--begin::Separator-->
                                            <span
                                                class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"></span>
                                            <!--end::Separator-->
                                        </span>
                                        <span class="opacity-75"> Xem hóa đơn và thanh toán để trải nghiệm dịch vụ nạp
                                            tiền
                                            real-time.</span>
                                    </div>
                                    <!--end::Title-->
                                    <!--begin::Illustration-->
                                    <div
                                        class="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 mb-lg-12 delivery">
                                    </div>
                                    <!--end::Illustration-->
                                </div>
                                <!--end::Heading-->
                                <!--begin::Links-->
                                <div class="text-center">
                                    <!--begin::Link-->
                                    <router-link to="/depositRecharge"
                                        class="btn btn-sm bg-white btn-color-gray-800 me-2">Tạo
                                        hóa đơn</router-link>
                                    <!--end::Link-->
                                    <!--begin::Link-->
                                    <router-link class="btn btn-sm bg-white btn-color-white bg-opacity-20"
                                        to="/deposit">Xem hóa
                                        đơn và thanh toán</router-link>
                                    <!--end::Link-->
                                </div>
                                <!--end::Links-->
                            </div>
                            <!--end::Body-->
                        </div>
                    </div>
                    <div v-else class="card-user" style="overflow:scroll">
                        <div class="col-xl-12">
                            <!--begin::List Widget 1-->
                            <div>
                                <!--begin::Header-->
                                <div class="card-header border-0 pt-5">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bold text-gray-900">Ngày hôm nay</span>

                                    </h3>
                                    <span class="text-muted mt-1 fw-semibold fs-7">05/02/2005</span>
                                    <div class="card-toolbar">
                                        <!--begin::Menu-->



                                        <!--end::Menu-->
                                    </div>
                                </div>
                                <!--end::Header-->

                                <!--begin::Body-->
                                <div class="card-body pt-5">
                                    <!--begin::Item-->
                                    <div class="d-flex align-items-center mb-7">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-50px me-5">
                                            <span class="symbol-label bg-light-success">
                                                <i class="ki-duotone ki-abstract-26 fs-2x text-success"><span
                                                        class="path1"></span><span class="path2"></span></i> </span>
                                        </div>
                                        <!--end::Symbol-->

                                        <!--begin::Text-->
                                        <div class="d-flex flex-row" style="justify-content: space-between;width: 80%;">
                                            <a  class="text-gray-900 text-hover-primary fs-6 fw-bold">Nạp
                                                tiền</a>

                                            <span class="text-muted fw-bold">{{
                                                formatCurrency(this.summary.totalDepositQRToday)
                                            }} đ</span>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="d-flex align-items-center mb-7">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-50px me-5">
                                            <span class="symbol-label bg-light-warning">
                                                <i class="ki-duotone ki-pencil fs-2x text-warning"><span
                                                        class="path1"></span><span class="path2"></span></i> </span>
                                        </div>
                                        <!--end::Symbol-->

                                        <!--begin::Text-->
                                        <div class="d-flex flex-row" style="justify-content: space-between;width: 80%;">
                                            <a  class="text-gray-900 text-hover-primary fs-6 fw-bold">Đã chi
                                                tiêu</a>
                                            <span class="text-muted fw-bold">{{
                                                formatCurrency(this.summary.totalTopupToday) }}
                                                đ</span>

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="d-flex align-items-center mb-7">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-50px me-5">
                                            <span class="symbol-label bg-light-primary">
                                                <i class="ki-duotone ki-message-text-2 fs-2x text-primary"><span
                                                        class="path1"></span><span class="path2"></span><span
                                                        class="path3"></span></i> </span>
                                        </div>
                                        <!--end::Symbol-->

                                        <!--begin::Text-->
                                        <div class="d-flex flex-row" style="justify-content: space-between;width: 80%;">
                                            <a  class="text-gray-900 text-hover-primary fs-6 fw-bold">Doanh
                                                thu</a>

                                            <span class="text-muted fw-bold">{{
                                                formatCurrency(this.summary.totalFeeTopupToday)
                                            }} đ</span>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="d-flex align-items-center mb-7">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-50px me-5">
                                            <span class="symbol-label bg-light-danger">
                                                <i class="ki-duotone ki-disconnect fs-2x text-danger"><span
                                                        class="path1"></span><span class="path2"></span><span
                                                        class="path3"></span><span class="path4"></span><span
                                                        class="path5"></span></i> </span>
                                        </div>
                                        <!--end::Symbol-->

                                        <!--begin::Text-->
                                        <div class="d-flex flex-row" style="justify-content: space-between;width: 80%;">
                                            <a  class="text-gray-900 text-hover-primary fs-6 fw-bold">Khách
                                                cũ</a>

                                            <span class="text-muted fw-bold">{{ this.balanceSummary.oldUsers }}</span>
                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="d-flex align-items-center ">
                                        <!--begin::Symbol-->
                                        <div class="symbol symbol-50px me-5">
                                            <span class="symbol-label bg-light-info">
                                                <i class="ki-duotone ki-security-user fs-2x text-info"><span
                                                        class="path1"></span><span class="path2"></span></i> </span>
                                        </div>
                                        <!--end::Symbol-->

                                        <!--begin::Text-->
                                        <div class="d-flex flex-row" style="justify-content: space-between;width: 80%;">
                                            <a  class="text-gray-900 text-hover-primary fs-6 fw-bold">Khách
                                                mới</a>
                                            <span class="text-muted fw-bold">{{ this.balanceSummary.newUsersToday
                                                }}</span>

                                        </div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Item-->

                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::List Widget 1-->
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>
<script>

import ApexCharts from "apexcharts";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "DashBoardAdmin",
    data() {
        return {
            spencapByBusiness:[],
            summary: {},
            balanceSummary: {},
            currency: "",
            timezoneIndex: 20,
            timezoneIndex1: 1,
            timezones: [
                'Pacific/Midway',      // -11
                'Pacific/Niue',        // -11
                'Pacific/Honolulu',    // -10
                'Pacific/Pago_Pago',   // -11
                'America/Anchorage',   // -9
                'America/Los_Angeles', // -8
                'America/Tijuana',     // -8
                'America/Denver',      // -7
                'America/Phoenix',     // -7
                'America/Chicago',      // -6
                'America/Mexico_City', // -6
                'America/Regina',      // -6
                'America/New_York',    // -5
                'America/Caracas',     // -4.5
                'America/Halifax',     // -4
                'America/Argentina/Buenos_Aires', // -3
                'America/Sao_Paulo',   // -3
                'Atlantic/Azores',     // -1
                'Atlantic/Cape_Verde', // -1
                'UTC',                 // 0
                'Europe/London',       // +0
                'Europe/Paris',        // +1
                'Europe/Istanbul',     // +3
                'Europe/Moscow',       // +3
                'Asia/Dubai',          // +4
                'Asia/Yekaterinburg',  // +5
                'Asia/Almaty',         // +6
                'Asia/Colombo',        // +6
                'Asia/Bangkok',        // +7
                'Asia/Ho_Chi_Minh',    // +7
                'Asia/Jakarta',        // +7
                'Asia/Taipei',         // +8
                'Asia/Singapore',      // +8
                'Asia/Kuala_Lumpur',   // +8
                'Asia/Hong_Kong',      // +8
                'Asia/Ulaanbaatar',    // +8
                'Asia/Pyongyang',      // +9
                'Asia/Tokyo',          // +9
                'Australia/Darwin',    // +9.5
                'Australia/Adelaide',  // +10
                'Australia/Sydney',    // +10
                'Pacific/Guam',        // +10
                'Australia/Brisbane',  // +10
                'Asia/Vladivostok',    // +10
                'Pacific/Fiji',        // +12
                'Pacific/Tongatapu',   // +13
                'Pacific/Apia',        // +13
            ],
            status: "",
            dateStart: null,
            dateEnd: null,
            keyword: "",
            dataUser: { language: "VN" },
            isAdmin: false,
            isSupport: false,
            colorsAimChart: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd"],



            selectedOption: this.$NOLIMITResource["VN"].DashBoard.Day,
            dropdownOptions: [this.$NOLIMITResource["VN"].DashBoard.Day, this.$NOLIMITResource["VN"].DashBoard.Month, this.$NOLIMITResource["VN"].DashBoard.Year],
            username: "",
            onLoading: true,
            hasData: true,
            pageSize: 10,
            balanceUser: 0,
            pageNumber: 1,
            sortingAsc: null,
            sortingNumber: null,
            isAccountant: false,
            columns: [
                { label: 'Id topup', width: 250, property: "TopupOrderId" },
                { label: this.$NOLIMITResource["VN"].NameAds, width: 300, property: "Name" },
                { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
                { label: this.$NOLIMITResource["VN"].Note, width: 300, property: "Note" },
                { label: this.$NOLIMITResource["VN"].Amount, width: 300, property: "Amount" },
                { label: this.$NOLIMITResource["VN"].TopupFee, width: 300, property: "Fee" },
                { label: this.$NOLIMITResource["VN"].PaymentAmount, width: 300, property: "AmountDisplay" },
                { label: this.$NOLIMITResource["VN"].Status, width: 300, property: "Status" },
                { label: this.$NOLIMITResource["VN"].LimitBeforeTopup, width: 300, property: "LimitBefore" },
                { label: this.$NOLIMITResource["VN"].LimitAfterTopup, width: 300, property: "LimitAfter" },
                { label: this.$NOLIMITResource["VN"].Created, width: 300, property: "CreateDate" },
            ],
            columnsAccountant: [
                { label: 'Id topup', width: 250, property: "TopupOrderId" },
                { label: this.$NOLIMITResource["VN"].NameAds, width: 300, property: "Name" },
                { label: this.$NOLIMITResource["VN"].UserName, width: 300, property: "UserName" },
                { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
                { label: this.$NOLIMITResource["VN"].Note, width: 300, property: "Note" },
                { label: this.$NOLIMITResource["VN"].Amount, width: 300, property: "Amount" },
                { label: this.$NOLIMITResource["VN"].TopupFee, width: 300, property: "Fee" },
                { label: this.$NOLIMITResource["VN"].PaymentAmount, width: 300, property: "AmountDisplay" },
                { label: this.$NOLIMITResource["VN"].Status, width: 300, property: "Status" },
                { label: this.$NOLIMITResource["VN"].LimitBeforeTopup, width: 300, property: "LimitBefore" },
                { label: this.$NOLIMITResource["VN"].LimitAfterTopup, width: 300, property: "LimitAfter" },
                { label: this.$NOLIMITResource["VN"].Created, width: 300, property: "CreateDate" },
            ],
            startDate: null,
            endDate: null,
            totalAdminPlus: 0,
            totalAdminMinus: 0,
            totalTopupAmount: 0,
            totalRecharge: 0,
            totalTransferMoney: 0,
            totalReceiveMoney: 0,
            totalRechargeToday: 0,
            totalTopupAmountToday: 0,
            totalTransferMoneyToday: 0,
            totalReceiveMoneyToday: 0,
            totalAdminPlusToday: 0,
            totalAdminMinusToday: 0,
            dataApexchartMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataApexchartMonthTopup: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataApexchartDay: [0, 0, 0, 0, 0, 0, 0],
            dataApexchartDayTopup: [0, 0, 0, 0, 0, 0, 0],
            dataApexchartYear: [0, 0, 0, 0, 0,],
            dataApexchartYearTopup: [0, 0, 0, 0, 0,],
            categoriesMonth: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            months: this.$NOLIMITResource["VN"].DashBoard.Months,
            daysOfWeek: this.$NOLIMITResource["VN"].DashBoard.DaysOfWeek,
            hourOfDay: [
                "1:00",
                "2:00",
                "3:00",
                "4:00",
                "5:00",
                "6:00",
                "7:00",
                "8:00",
                "9:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
                "24:00",
            ],
            apexChart: null,
        }
    },
    watch: {
        dataUser: {
            handler: function () {
                this.selectedOption = this.$NOLIMITResource[this.dataUser.language].DashBoard.Day;
                this.dropdownOptions = [this.$NOLIMITResource[this.dataUser.language].DashBoard.Day, this.$NOLIMITResource[this.dataUser.language].DashBoard.Month, this.$NOLIMITResource[this.dataUser.language].DashBoard.Year];
                this.months = this.$NOLIMITResource[this.dataUser.language].DashBoard.Months;
                this.daysOfWeek = this.$NOLIMITResource[this.dataUser.language].DashBoard.DaysOfWeek;
                this.updateSeriesLine();
                this.updateSeriesLineMonth();
                this.updateSeriesLineDay();
                this.columns = [
                    { label: 'Id topup', width: 250, property: "TopupOrderId" },
                    { label: this.$NOLIMITResource[this.dataUser.language].NameAds, width: 300, property: "Name" },
                    { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 300, property: "Note" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 300, property: "Amount" },
                    { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 300, property: "Fee" },
                    { label: this.$NOLIMITResource[this.dataUser.language].PaymentAmount, width: 300, property: "AmountDisplay" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 300, property: "Status" },
                    { label: this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup, width: 300, property: "LimitBefore" },
                    { label: this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup, width: 300, property: "LimitAfter" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Created, width: 300, property: "CreateDate" },
                ];
                this.columnsAccountant = [
                    { label: 'Id topup', width: 250, property: "TopupOrderId" },
                    { label: this.$NOLIMITResource[this.dataUser.language].NameAds, width: 300, property: "Name" },
                    { label: this.$NOLIMITResource[this.dataUser.language].UserName, width: 300, property: "UserName" },
                    { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 300, property: "Note" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 300, property: "Amount" },
                    { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 300, property: "Fee" },
                    { label: this.$NOLIMITResource[this.dataUser.language].PaymentAmount, width: 300, property: "AmountDisplay" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 300, property: "Status" },
                    { label: this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup, width: 300, property: "LimitBefore" },
                    { label: this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup, width: 300, property: "LimitAfter" },
                    { label: this.$NOLIMITResource[this.dataUser.language].Created, width: 300, property: "CreateDate" },
                ];
            },
            deep: true
        },
        dataApexchartDay: {
            handler: function () {
                // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartDay thay đổi
                if (this.selectedOption == this.$NOLIMITResource["VN"].DashBoard.Day) {
                    this.updateSeriesLineDay();
                }
            },
            deep: true
        },
        dataApexchartYear: {
            handler: function () {
                // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartYear thay đổi
                if (this.selectedOption == this.$NOLIMITResource["VN"].DashBoard.Year) {
                    this.updateSeriesLine();
                }
            },
            deep: true // Watcher sẽ theo dõi sâu vào các phần tử của mảng hoặc đối tượng
        },
        dataApexchartMonth: {
            handler: function () {
                // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartMonth thay đổi
                if (this.selectedOption == this.$NOLIMITResource["VN"].DashBoard.Month) {
                    this.updateSeriesLineMonth();
                }
            },
            deep: true
        },

      
    },
    computed: {
        ...mapGetters("listModule", [
            "onShowMenuFilter",
            "checkRow",
            "displayRow",
            "isShowModalPickRow",
            "formatCurrency",
            "formatDateTime",
            "convertToEndOfDay",
            "onShowMenuFilterStatus",
            "getStatusAds",
            "language",
        ]),
        dynamicLabels() {

            const currentYear = new Date().getFullYear();

            return [
                (currentYear - 4).toString(),
                (currentYear - 3).toString(),
                (currentYear - 2).toString(),
                (currentYear - 1).toString(),
                currentYear.toString()
            ];
        },
        dynamicLabelsMonth() {
            const currentTime = new Date();
            return [this.months[(currentTime.getMonth() - 11 + 12) % 12],
            this.months[(currentTime.getMonth() - 10 + 12) % 12],
            this.months[(currentTime.getMonth() - 9 + 12) % 12],
            this.months[(currentTime.getMonth() - 8 + 12) % 12],
            this.months[(currentTime.getMonth() - 7 + 12) % 12],
            this.months[(currentTime.getMonth() - 6 + 12) % 12],
            this.months[(currentTime.getMonth() - 5 + 12) % 12],
            this.months[(currentTime.getMonth() - 4 + 12) % 12],
            this.months[(currentTime.getMonth() - 3 + 12) % 12],
            this.months[(currentTime.getMonth() - 2 + 12) % 12],
            this.months[(currentTime.getMonth() - 1 + 12) % 12],
            this.months[(currentTime.getMonth() + 12) % 12]]
        },
        dynamicLabelsDay() {
            const currentTime = new Date();
            return [
                this.daysOfWeek[(currentTime.getDay() - 6 + 7) % 7],
                this.daysOfWeek[(currentTime.getDay() - 5 + 7) % 7],
                this.daysOfWeek[(currentTime.getDay() - 4 + 7) % 7],
                this.daysOfWeek[(currentTime.getDay() - 3 + 7) % 7],
                this.daysOfWeek[(currentTime.getDay() - 2 + 7) % 7],
                this.daysOfWeek[(currentTime.getDay() - 1 + 7) % 7],
                this.daysOfWeek[currentTime.getDay()],
            ];
        },
        timezone2() {
            return this.timezones[this.timezoneIndex];
        },
        timezone1() {
            return this.timezones[this.timezoneIndex1];
        },
        attributes() {
            return [{
                highlight: true,
                dates: this.dateStart,
            }];
        },
    },
    async created() {
        this.selectedOption = this.$NOLIMITResource["VN"].DashBoard.Day; // Đặt mặc định là ngày trước khi gọi biểu đồ

        await this.$apiService.getBalance().then((res) => {
            this.balanceSummary = res.data.data.summary;
        });

        this.$nextTick(() => {
            this.initApexCharts(); // Khởi tạo biểu đồ sau khi dữ liệu đã sẵn sàng
            this.updateSeriesLineDay(); // Đảm bảo mặc định hiển thị ngày
        });

        await this.$apiService
            .getUserProfile().then((response) => {
                if (response.data.data.language == "VN" || response.data.data.language == "EN") {
                    this.dataUser = response.data.data;
                } else {
                    this.dataUser = response.data.data;
                    this.dataUser.language = "VN";
                }
                this.idUser = response.data.data.userId;
                this.username = response.data.data.username.charAt(0).toUpperCase();
                this.balanceUser = response.data.data.balance;
                this.isAdmin = response.data.data.isAdmin;
                this.isSupport = response.data.data.isSupport;
                if (response.data.data.isAccountant == true) {
                    this.isAccountant = true;

                }
            })
            .catch((error) => {
                console.error("Lỗi khi lấy dữ liệu:", error);
            });

        await this.$apiService.filterAllUserSpendingStatisticByAdminDefault(this.keyword).then((res) => {
            
            this.summary = res.data.data.summary;
            this.spencapByBusiness = res.data.data.summary.spencapByBusiness
            console.log(res.data.data.summary.spencapByBusiness)
        });
        await this.$apiService.getBalance().then((res) => {
            this.balanceSummary = res.data.data.summary;
        });

        await this.$apiService.getAllUserSpendingStatistic().then((res) => {
            const currentTime = new Date();


            for (let i = 0; i < res.data.data.length; i++) {
                const transactionTime = new Date(res.data.data[i].date);
                for (let j = 0; j < 5; j++) {
                    const startOfHour = new Date(currentTime.getFullYear(), 1);
                    const endOfHour = new Date(currentTime.getFullYear() - j + 1, 1);
                    if (transactionTime >= startOfHour && transactionTime <= endOfHour) {
                        this.dataApexchartYear[4 - j] += (res.data.data[i].totalDeposit+res.data.data[i].totalDepositPlusMoney+res.data.data[i].totalReceiveMoneyAdmin);
                        this.dataApexchartYearTopup[4 - j] += res.data.data[i].totalTopup;
                        break;
                    }
                }
                for (let j = 0; j < 12; j++) {
                    const startOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth() - j, 1);
                    const endOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth() - j + 1, 1);
                    if (transactionTime >= startOfMonth && transactionTime <= endOfMonth) {
                        this.dataApexchartMonth[11 - j] += (res.data.data[i].totalDeposit+res.data.data[i].totalDepositPlusMoney+res.data.data[i].totalReceiveMoneyAdmin);
                        this.dataApexchartMonthTopup[11 - j] += res.data.data[i].totalTopup;
                        break;
                    }
                }
                for (let j = 0; j < 7; j++) {
                    const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() - j, 0, 0, 0);
                    const endOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() - j, 23, 59, 59);
                    if (transactionTime >= startOfDay && transactionTime <= endOfDay) {
                        this.dataApexchartDay[6 - j] += (res.data.data[i].totalDeposit+res.data.data[i].totalDepositPlusMoney+res.data.data[i].totalReceiveMoneyAdmin);
                        this.dataApexchartDayTopup[6 - j] += res.data.data[i].totalTopup;
                        break;
                    }
                }
            }




        });
    },
    mounted() {
        this.$nextTick(() => {
            this.initApexCharts();

        })

    },
    beforeUnmount() {
        if (this.amRoot) {
            this.amRoot.dispose();
        }
        if (this.apexChart) {
            this.apexChart.destroy();
        }

    },


    methods: {
        ...mapActions("listModule", ["toggleMenuFilter", "toggleModalDisplayRow", "swalSuccess", "swalError", "swalWarning", "toggleFilterStatus", "setStatusAds"]),
    

        initApexCharts() {

            const element = document.getElementById("kt_apexcharts_3");
            if (!element) {
                return;
            }
            let selectedSeries = [];
            let selectedCategories = [];

            if (this.selectedOption === this.$NOLIMITResource[this.dataUser.language].DashBoard.Day) {
                selectedSeries = [
                    { name: "Recharge", data: this.dataApexchartDay },
                    { name: "Topup", data: this.dataApexchartDayTopup },
                ];
                selectedCategories = this.dynamicLabelsDay;
            } else if (this.selectedOption === this.$NOLIMITResource[this.dataUser.language].DashBoard.Month) {
                selectedSeries = [
                    { name: "Recharge", data: this.dataApexchartMonth },
                    { name: "Topup", data: this.dataApexchartMonthTopup },
                ];
                selectedCategories = this.dynamicLabelsMonth;
            } else {
                selectedSeries = [
                    { name: "Recharge", data: this.dataApexchartYear },
                    { name: "Topup", data: this.dataApexchartYearTopup },
                ];
                selectedCategories = this.dynamicLabels;
            }
            const height = 400;
            const labelColor = "#99a1b7";

            const baseColor = "#7239ea";
            const lightColor = "#f8f5ff";
            const borderColor = "#f1f1f4";
            const colors = ["#7239ea", "#ef875c", "#f6cb70"];

            const options = {
                series: selectedSeries,
                chart: {
                    fontFamily: "inherit",
                    type: "area",
                    height: height,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {},
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: "solid",
                    opacity: 1,
                },
                stroke: {
                    curve: "smooth",
                    show: true,
                    width: 3,
                    colors: colors,
                },
                xaxis: {
                    categories: selectedCategories,
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        style: {
                            colors: labelColor,
                            fontSize: "12px",
                        },
                    },
                    crosshairs: {
                        position: "front",
                        stroke: {
                            color: baseColor,
                            width: 1,
                            dashArray: 3,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: labelColor,
                            fontSize: "12px",
                        },
                        formatter: function (value) {
                            // Kiểm tra nếu là số nguyên thì hiển thị như số nguyên
                            return Number.isInteger(value) ? value.toLocaleString("en-US") : value.toFixed(2);
                        },
                    },
                },
                states: {
                    normal: {
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                    hover: {
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: "none",
                            value: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: "12px",
                    },
                    y: {
                        formatter: function (val) {
                            if (val == null) {
                                return "_";
                            }

                            // Kiểm tra xem có số thập phân hay không
                            if (Number.isInteger(val)) {
                                // Nếu là số nguyên, chỉ cần định dạng nguyên với toLocaleString
                                return val.toLocaleString("en-US") + ' đ';
                            } else {
                                // Nếu có số thập phân, làm tròn đến hai chữ số sau dấu phẩy
                                const roundedNumber = Number(val.toFixed(2));
                                return roundedNumber.toLocaleString("en-US") + ' đ';
                            }

                        },
                    },
                },
                colors: [lightColor],
                grid: {
                    borderColor: borderColor,
                    strokeDashArray: 4,
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                markers: {
                    strokeColor: "#ef875c",
                    strokeWidth: 3,
                },
            };


            this.apexChart = new ApexCharts(element, options);
            this.apexChart.render();
        },


        onFilterSearch() {
            if (
                (this.dateStart == null ||
                    this.dateEnd == null ||
                    this.dateEnd < this.dateStart)
            ) {
                this.$apiService.filterAllUserSpendingStatisticByAdminDefault(this.keyword).then((res) => {
                    this.summary = res.data.data.summary;
                });
            } else {
                const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
                this.$apiService
                    .filterAllUserSpendingStatisticByAdmin(
                        this.dateStart.toISOString(),
                        dateEndConvert,
                        this.keyword,
                    )
                    .then((res) => {
                        this.summary = res.data.data.summary;
                    });
            }
            this.toggleMenuFilter();
        },


        formatCurrencyA(number) {
            if (number == null) {
                return "_";
            }

            if (Number.isInteger(number)) {
                return number.toLocaleString("en-US");
            } else {
                const roundedNumber = Number(number.toFixed(2));
                return roundedNumber.toLocaleString("en-US");
            }
        },
        updateSeriesLine() {
            if (this.apexChart) {
                this.apexChart.updateSeries([
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
                        data: this.dataApexchartYear, // Thay đổi dữ liệu phù hợp  
                    },
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
                        data: this.dataApexchartYearTopup, // Thay đổi dữ liệu phù hợp
                    },
                    // {
                    //   name: "Tk giao",
                    //   data: [20, 25, 30, 40, 35, 30, 35, 50, 55, 45, 35, 40], // Thay đổi dữ liệu phù hợp
                    // },
                ], false, true);
                this.apexChart.updateOptions({
                    xaxis: {
                        categories: this.dynamicLabels
                    }
                });
            }
        },

        updateSeriesLineMonth() {
            if (this.apexChart) {
                this.apexChart.updateSeries([
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
                        data: this.dataApexchartMonth, // Thay đổi dữ liệu phù hợp  
                    },
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
                        data: this.dataApexchartMonthTopup, // Thay đổi dữ liệu phù hợp
                    },
                ], false, true);
                this.apexChart.updateOptions({
                    xaxis: {
                        categories: this.dynamicLabelsMonth
                    }
                });
            }
        },
        updateSeriesLineDay() {
            if (this.apexChart) {
                this.apexChart.updateSeries([
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
                        data: this.dataApexchartDay, // Thay đổi dữ liệu phù hợp  
                    },
                    {
                        name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
                        data: this.dataApexchartDayTopup, // Thay đổi dữ liệu phù hợp
                    },
                ], false, true);
                this.apexChart.updateOptions({
                    xaxis: {
                        categories: this.dynamicLabelsDay
                    }
                });
            }
        },
  

        selectOption(option) {
            this.selectedOption = option;

            // Update series based on the selected option 
            if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.Day) {
                this.updateSeriesLineDay();
                this.$apiService.filterAllUserSpendingStatisticByAdminDefault(this.keyword).then((res) => {
                    this.summary = res.data.data.summary;
                });
            } else if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.Month) {
                this.updateSeriesLineMonth();
                this.$apiService.filterAllUserSpendingStatisticByAdminDefault("month").then((res) => {
                    this.summary = res.data.data.summary;
                });
            } else if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.Year) {

                this.updateSeriesLine();
                this.$apiService.filterAllUserSpendingStatisticByAdminDefault("year").then((res) => {
                    this.summary = res.data.data.summary;
                });
            }



            // Move the selected option to the top
            this.dropdownOptions = this.dropdownOptions.filter(opt => opt !== option);
            this.dropdownOptions.unshift(option);
            if (this.apexChart) {
                this.apexChart.destroy();
            }
            this.initApexCharts();
        },
        getFirstUsername(name) {
            if (name.length > 0) {
                return name.charAt(0).toUpperCase();
            } else {
                return '';
            }
        },
        formatDateRemain(createDate) {
            let currentDate = new Date();
            const createdDateTime = new Date(createDate);
            const timeDifference = currentDate - createdDateTime;
            const oneMinuteInMillis = 60 * 1000;
            const oneHourInMillis = 60 * oneMinuteInMillis;
            const oneDayInMillis = 24 * oneHourInMillis;

            if (timeDifference < oneMinuteInMillis) {
                // Nếu thời gian tạo nhỏ hơn 1 phút
                const seconds = Math.floor(timeDifference / 1000);
                return `${seconds} giây trước`;
            } else if (timeDifference < oneHourInMillis) {
                // Nếu thời gian tạo nhỏ hơn 1 giờ
                const minutes = Math.floor(timeDifference / oneMinuteInMillis);
                return `${minutes} phút trước`;
            } else if (timeDifference < oneDayInMillis) {
                // Nếu thời gian tạo nhỏ hơn 1 ngày
                const hours = Math.floor(timeDifference / oneHourInMillis);
                return `${hours} giờ trước`;
            } else if (timeDifference < 2 * oneDayInMillis) {
                // Nếu thời gian tạo là hơn 1 ngày trước
                return "1 ngày trước";
            } else {
                // Nếu thời gian tạo lớn hơn 1 ngày
                const days = Math.floor(timeDifference / oneDayInMillis);
                return `${days} ngày trước`;
            }
        },



    },
};
</script>

<style scoped>
/* Add any styles you need here */

.show-dropfilter {
    z-index: 107;
    position: absolute;
    inset: 61px 176px auto auto;
    margin: 0px;
    top: 135px;
    right: 20px;
    text-align: start;
}

.show-dropfilter-2 {
    z-index: 107;
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    top: 145px;
    left: 20px;
    text-align: start;
}

.apex-chart-block {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 80% !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.am-chart-block {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 40%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.legend-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.legend-color {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.color-napvi {
    background-color: #7239ea;
}

.color-topup {
    background-color: #ef875c;
}

.color-tkgiao {
    background-color: #f6cb70;
}

.dropdown-container {
    margin-left: auto;
    position: relative;
}

.dropdown {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100px;
}

.dropdown-menu a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
}

.dropdown-menu a:hover {
    background-color: #f0f0f0;
}

.header-apex-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Style Card header */
.card-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.card-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
}

.card-content {
    margin-top: 10px;
}

.card-content .amount {
    font-size: 24px;
    font-weight: bold;
}

.card-content .description {
    color: #888;
    font-size: 14px;
    margin-top: 5px;
}

.card-content .total {
    font-size: 18px;
    margin-top: 10px;
}

.card-blue {
    color: #546e7a;
}

.card-blue .card-icon {
    background-color: #546e7a;
}

.card-body-top {
    justify-items: flex-start;
    padding: 0;
}

.card-red {
    color: #e91e63;
}

.card-red .card-icon {
    background-color: #e91e63;
}

.card-orange {
    color: #ff9800;
}

.card-orange .card-icon {
    background-color: #ff9800;
}

.card-green {
    color: #4caf50;
}

.card-green .card-icon {
    background-color: #4caf50;
}

.card-more {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #aaa;
    cursor: pointer;
}

.ki-duotone {
    font-size: 4rem;
    color: #fff;
}

/** style card user */

.card-user {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 18%;
    height: 525px;
}

.card-header-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card-header-user h3 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.card-header-user a {
    font-size: 14px;
    color: #1b84ff;
    text-decoration: none;
}

.user-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.user-item h1 {
    background: #1b84ff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;

}

.circle {
    width: 40px;
    height: 40px;
    background-color: #4CAF50;
    /* Màu nền của khung tròn */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    font-weight: bold;
}

.user-info {
    flex-grow: 1;
}

.user-info h4 {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.user-info p {
    margin: 0;
    font-size: 12px;
    color: #888;
}

.user-amount {
    font-size: 14px;
    color: #1b84ff;
    font-weight: bold;
    min-width: 120px;
}

.row-2-apexchart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.row-3-amchart {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 20px;
}

.table thead th:last-child,
.table tbody td:last-child {
    position: relative !important;
    right: -1px;
    top: 4px;
    min-width: 150px !important;
    width: 120px;
    display: flex;
    align-items: center;
    z-index: 2;
    border-top: none;
    background: #fff;
    border-bottom: none;
}

.block-topup-history {
    width: 58%;
    height: 596px;
}

@media only screen and (min-width: 100px) {
    .apex-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 100% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-bottom: 20px;
    }

    .card-user {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100% !important;
        height: 525px;
    }

    .row-2-apexchart {
        display: block;
    }

    .block-topup-history {
        width: 100% !important;
        height: 596px;
    }

    .am-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 100% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .row-3-amchart {
        display: block;

    }

    .card {
        flex: 1 1 90%;
    }
}

@media only screen and (min-width: 768px) {
    .card {
        flex: 1 1 40%;
    }
}

@media only screen and (min-width: 1700px) {
    .apex-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 100% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-bottom: 20px;
    }

    .card-user {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100% !important;
        height: 525px;
    }

    .row-2-apexchart {
        display: block;
    }

    .block-topup-history {
        width: 100% !important;
        height: 596px;
    }

    .am-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 100% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .row-3-amchart {
        display: block;
    }

    .card {
        flex: 1 1 40%;
    }

}

@media only screen and (min-width: 2200px) {
    .row-3-amchart {
        display: flex;

    }

    .block-topup-history {
        width: 58% !important;
        height: 596px;
    }

    .am-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 40% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .apex-chart-block {
        padding: 20px;
        border-radius: 10px;
        background: #fff;
        width: 80% !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-bottom: 0px;
    }

    .card-user {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 18% !important;
        height: 525px;
    }

    .row-2-apexchart {
        display: flex;
    }

    .bg-recharge {
        background-image: url("./../../../../public/frontend/media/svg/shapes/top-green.png");
    }

    .card {
        flex: 1 1 20%;
    }
}
</style>