<template>
    <div v-if="this.isAdmin != true && this.isSupport != true"> {{
        this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div>
    <!--begin::Content wrapper-->
    <div v-else class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6"
            style="padding-top: 0px !important;padding-bottom: 10px !important;">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Nhãn dán doanh nghiệp
                    </h1>
                    <!--end::Title-->
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container">
                <!--begin::Card-->
                <div class="card">
                    <!--begin::Card header-->
                    <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
                        <!--begin::Card title-->
                        <div class="card-title">
                            <!--begin::Search-->
                            <div class="d-flex align-items-center position-relative my-1">
                                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                    <span @click="onEnterSearchFirst" class="path1"
                                        style="cursor:pointer;z-index: 2;"></span>
                                    <span class="path2"></span>
                                </i>
                                <input v-on:keyup.enter="onEnterSearchFirst" v-model="inputValue" type="text"
                                    class="form-control form-control-solid w-250px ps-12"
                                    :placeholder="this.$NOLIMITResource[this.dataUser.language].UserManagement.TextSearch" />
                            </div>
                            <!--end::Search-->
                        </div>
                        <!--begin::Card title-->
                        <!--begin::Card toolbar-->

                        <div class="card-toolbar" style="margin-left: auto;">
                            <a @click="openModalAllCardInfo()" id="selectedall__toolbar">Thông tin các nhãn dán</a>


                        </div>
                        <!--end::Card toolbar-->
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-0" style="margin-top: 20px;">
                        <!--begin::Table-->
                        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                            <thead>
                                <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                    <template v-for="(column, index) in columns" :key="index">
                                        <th v-if="shouldDisplay(index)" class="min-w-125px" :class="{
                                            sorting_desc: this.sortingNumber == index,
                                            sorting_asc: this.sortingAsc == index,
                                        }" :style="{ 'min-width': column.width + 'px !important' }">
                                            <template v-if="shouldDisplay(index)">
                                                <div @mousedown="this.startResize($event, index)">
                                                    <span @click="
                                                        onSorting(index, column.property, 'changeSort')
                                                        ">{{ column.label }}</span>
                                                </div>
                                            </template>
                                        </th>
                                    </template>


                                    <th v-if="this.isAdmin" class="text-end min-w-70px"> {{
                                        this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                                    <th v-else style="min-width: 0px !important; width: 0px !important;"></th>
                                </tr>
                            </thead>
                            <div class="loading-1" v-if="onLoading">
                                <span class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </div>
                            <div style="margin-top: 20px;" v-if="!this.onLoading && this.hasData == false">{{
                                this.$NOLIMITResource[this.dataUser.language].UserManagement.Nodata }}</div>
                            <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                                <tr v-for="user in users" :key="user.userId">
                                    <td>
                                        <a class="text-gray-800 text-hover-primary mb-1">{{ user.userId }}</a>
                                    </td>
                                    <td v-if="this.displayRow.username">
                                        <a class="text-gray-800 text-hover-primary mb-1">{{ user.username }}</a>
                                    </td>
                                    <td>
                                        <a class="text-gray-800 text-hover-primary mb-1">
                                            <div v-if="user.businessId != null" :class="`badge badge-light-success`">
                                                {{ user.businessName }}
                                            </div>
                                            <div v-else :class="`badge badge-light-primary`">
                                                Chưa có
                                            </div>
                                        </a>
    
                                       
                                    </td>
                                    <td v-if="this.displayRow.fullnameUser">
                                        <a class="text-gray-600 text-hover-primary mb-1">{{
                                            user.name
                                            }}</a>
                                    </td>
                                    <td v-if="this.isAdmin" class="text-end" :class="{
                                        active__update__combobox:
                                            onShowMenu && currenOnshowId === user.userId,
                                    }">
                                        <a @click="toggleMenuItem(user.userId)"
                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">{{
                                                this.$NOLIMITResource[this.dataUser.language].SeeDetail }} <i
                                                class="ki-duotone ki-down fs-5 ms-1"></i></a>
                                        <!--begin::Menu-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                            :class="{
                                                show: onShowMenu && currenOnshowId === user.userId,
                                            }" data-kt-menu="true">

                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a v-if="!user.businessId" @click="openModalPickBusiness(user.userId)"
                                                    class="menu-link px-3" data-kt-customer-table-filter="delete_row">{{
                                                        this.$NOLIMITResource[this.dataUser.language].UserManagement.AddTagBusiness
                                                    }}</a>
                                                <a v-else @click="deleteBusiness(user.userId)" class="menu-link px-3"
                                                    data-kt-customer-table-filter="delete_row">{{
                                                        this.$NOLIMITResource[this.dataUser.language].UserManagement.DeleteTagBusiness
                                                    }}</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </td>
                                    <td v-else style="min-width: 0px !important; width: 0px !important;"></td>
                                </tr>
                                <tr id="total">
                                    <td class="fix-data-footer" colspan="3" style="font-weight: bold"> <img
                                            src="./../../../../../public/frontend/media/logos/logo3.png"
                                            style="width:30px;height:auto" />
                                        {{ this.userAll.length }} {{
                                            this.$NOLIMITResource[this.dataUser.language].UserManagement.User }}</td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div
                                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                <div class="dataTables_length" id="kt_customers_table_length">
                                    <label>
                                        <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage"
                                            label="value"></v-select>
                                    </label>
                                </div>
                            </div>
                            <div
                                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                <div class="dataTables_paginate paging_simple_numbers" id="kt_customers_table_paginate">
                                    <ul v-if="this.totalPage >= 4" class="pagination">
                                        <li class="paginate_button page-item previous"
                                            :class="{ disabled: this.pageNumber == 1 }"
                                            id="kt_customers_table_previous">
                                            <a @click="prevPage" aria-controls="kt_customers_table" data-dt-idx="0"
                                                tabindex="0" class="page-link"><i class="previous"></i></a>
                                        </li>
                                        <li v-for="index in 4" :key="index" class="paginate_button page-item"
                                            :class="{ active: pageNumber == this.firstPage + index }">
                                            <a @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table" data-dt-idx="1" tabindex="0"
                                                class="page-link">{{ this.firstPage + index }}</a>
                                        </li>
                                        <li class="paginate_button page-item next"
                                            :class="{ disabled: this.pageNumber == this.totalPage }"
                                            id="kt_customers_table_next">
                                            <a @click="nextPage" aria-controls="kt_customers_table" data-dt-idx="5"
                                                tabindex="0" class="page-link"><i class="next"></i></a>
                                        </li>
                                    </ul>
                                    <ul v-if="this.totalPage < 4" class="pagination">
                                        <li class="paginate_button page-item previous"
                                            :class="{ disabled: this.pageNumber == 1 }"
                                            id="kt_customers_table_previous">
                                            <a @click="prevPage" aria-controls="kt_customers_table" data-dt-idx="0"
                                                tabindex="0" class="page-link"><i class="previous"></i></a>
                                        </li>
                                        <li v-for="index in totalPage" :key="index" class="paginate_button page-item"
                                            :class="{ active: pageNumber == this.firstPage + index }">
                                            <a @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table" data-dt-idx="1" tabindex="0"
                                                class="page-link">{{ this.firstPage + index }}</a>
                                        </li>
                                        <li class="paginate_button page-item next"
                                            :class="{ disabled: this.pageNumber == this.totalPage }"
                                            id="kt_customers_table_next">
                                            <a @click="nextPage" aria-controls="kt_customers_table" data-dt-idx="5"
                                                tabindex="0" class="page-link"><i class="next"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--end::Table-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Card-->
                <!--begin::Modals-->
                <!--begin::Modal - Card - Info-->
                <div class="modal fade show" :class="{ block: onShowModalAllTagBusiness }" id="kt_modal_add_customer"
                    tabindex="-1" aria-hidden="true">
                    <!--begin::Modal dialog-->
                    <div class="modal-dialog modal-dialog-centered mw-650px">
                        <!--begin::Modal content-->
                        <div class="modal-content" style="width:160%">
                            <!--begin::Form-->
                            <form class="form" id="kt_modal_add_customer_form" action=""
                                @submit.prevent="openModalAddBatchCardInfo">
                                <!--begin::Modal header-->
                                <div class="modal-header" id="kt_modal_add_customer_header">
                                    <!--begin::Modal title-->
                                    <h2 class="fw-bold"> Thông tin các tag Business</h2>
                                    <!--end::Modal title-->
                                    <!--begin::Close-->
                                    <div @click="closeModalAllCardInfo" id="kt_modal_add_customer_close"
                                        class="btn btn-icon btn-sm btn-active-icon-primary">
                                        <i class="ki-duotone ki-cross fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </div>
                                    <!--end::Close-->
                                </div>
                                <!--end::Modal header-->
                                <!--begin::Modal body-->
                                <div class="modal-body py-10 px-lg-17">
                                    <!--begin::Scroll-->
                                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll"
                                        data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
                                        data-kt-scroll-max-height="auto"
                                        data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                        data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                        data-kt-scroll-offset="300px">
                                        <table class="table align-middle table-row-dashed fs-6 gy-5"
                                            id="kt_customers_table">
                                            <thead>
                                                <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                    <th class="min-w-125px">Số thẻ</th>
                                                    <th class="min-w-125px">Tên thẻ</th>
                                                    <th class="min-w-125px">Chức năng</th>
                                                </tr>
                                            </thead>
                                            <div class="loading" v-if="onLoading">
                                                <span class="spinner-border text-primary" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </div>
                                            <div id="noData" v-if="!this.onLoading && this.hasData == false">
                                                {{ this.$NOLIMITResource["VN"].EmptyData }}
                                            </div>
                                            <tbody v-if="!this.onLoading && this.hasData == true"
                                                class="fw-semibold text-gray-600">
                                                <tr v-for="item in listTagBusiness" :key="item.businessId">
                                                    <td>
                                                        <a class="text-gray-800 text-hover-primary mb-1">{{ item.name
                                                            }}</a>
                                                    </td>
                                                    <td>
                                                        <a class="text-gray-800 text-hover-primary mb-1">{{
                                                            item.createdDate }}</a>
                                                    </td>
                                                    <td class="text-end" style="display: flex; height: 75px;">

                                                        <a @click="confirmDeleteCard(item.businessId, item.name)"
                                                            class="btn btn-light-danger btn-sm"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-placement="bottom-end">Xóa</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>




                                    </div>
                                    <!--end::Scroll-->
                                </div>
                                <!--end::Modal body-->
                                <!--begin::Modal footer-->
                                <div class="modal-footer flex-center">
                                    <!--begin::Button-->
                                    <button @click="closeModalAllCardInfo" type="reset"
                                        id="kt_modal_add_customer_cancel" class="btn btn-light me-3">
                                        Đóng
                                    </button>
                                    <!--end::Button-->
                                    <!--begin::Button-->
                                    <button type="submit" id="kt_modal_add_customer_submit" class="btn btn-primary">

                                        <span @click="openModalAddBatchCardInfo" class="indicator-label"> Thêm nhãn
                                            dán</span>
                                        <span class="indicator-progress">Please wait...
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                    <!--end::Button-->
                                </div>
                                <!--end::Modal footer-->
                            </form>
                            <!--end::Form-->
                        </div>
                    </div>

                </div>
                <!--end::Modal - Card - Info-->
                <!--begin::Modal - Add CardInfo-->
                <div class="modal fade show" :class="{ block: isShowModalAddBatchCardInfo }"
                    id="kt_customers_export_modal" tabindex="-1" aria-hidden="true">
                    <!--begin::Modal dialog-->
                    <div class="modal-dialog modal-dialog-centered mw-650px">
                        <!--begin::Modal content-->
                        <div class="modal-content">
                            <!--begin::Modal header-->
                            <div class="modal-header">
                                <!--begin::Modal title-->
                                <h2 class="fw-bold" style="text-align: end;">Thêm các tag Business dạng: <br>
                                    Tên Business 1<br>
                                    Tên Business 2<br>
                                    Tên Business 3</h2>
                                <!--end::Modal title-->
                                <!--begin::Close-->
                                <div id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                                    <i class="ki-duotone ki-cross fs-1" @click="closeModalAddBatchCardInfo">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                    </i>
                                </div>
                                <!--end::Close-->
                            </div>
                            <!--end::Modal header-->
                            <!--begin::Modal body-->
                            <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                <!--begin::Form-->
                                <form id="kt_customers_export_form" class="form" action=""
                                    @submit.prevent="convertStringToArray()">

                                    <!--begin::Input group-->
                                    <div class="input-group input-group-solid " style="margin-bottom: 50px;">
                                        <label class="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                            <span>Thông tin thẻ</span>
                                            <span class="ms-1" data-bs-toggle="tooltip" title="Thông tin thẻ">
                                                <i class="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </i>
                                            </span>
                                        </label>
                                        <textarea v-model="this.cardInfoImport" class="form-control"
                                            style="width: 100%;" aria-label="With textarea"></textarea>
                                    </div>
                                    <!--end::Input group-->


                                    <!--begin::Actions-->
                                    <div class="text-center">
                                        <button type="reset" id="kt_customers_export_cancel" class="btn btn-light me-3"
                                            @click="closeModalAddBatchCardInfo">
                                            Đóng
                                        </button>
                                        <button type="submit" id="kt_customers_export_cancel"
                                            class="btn  btn-info me-3">
                                            Thêm
                                        </button>

                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Modal body-->
                        </div>
                        <!--end::Modal content-->
                    </div>
                    <!--end::Modal dialog-->
                </div>
                <!--end::Modal - Add CardInfo-->
                <!--begin::Modal - Pick Business-->
                <div class="modal fade show" :class="{ block: isShowModalPickBusiness }" id="kt_modal_add_customer" tabindex="-1" aria-modal="true" role="dialog"
                    >
                    <!--begin::Modal dialog-->
                    <div class="modal-dialog mw-700px">
                        <!--begin::Modal content-->
                        <div class="modal-content">
                            <!--begin::Modal header-->
                            <div class="modal-header  pb-0 border-0 d-flex justify-content-end">
                                <!--begin::Close-->
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" @click="closeModalPickBusiness">
                                    <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span
                                            class="path2"></span></i>
                                </div>
                                <!--end::Close-->
                            </div>
                            <!--end::Modal header-->
                            <!--begin::Modal body-->
                            <div class="modal-body scroll-y mx-5 mx-xl-10 pt-0 pb-15">
                                <!--begin::Heading-->
                                <div class="text-center mb-13">
                                    <!--begin::Title-->
                                    <h1 class="d-flex justify-content-center align-items-center mb-3">
                                        Chọn nhãn doanh nghiệp cho người dùng {{ this.currentUserIdPickBusiness }}
                                    </h1>
                                    <!--end::Title-->
                                </div>
                                <!--end::Heading-->

                                <!--begin::Users-->
                                <div class="mh-475px scroll-y me-n7 pe-7" v-for="tagBusiness in listTagBusiness" :key="tagBusiness.businessId">
                                    <!--begin::User-->
                                    <div class="border border-hover-primary p-7 rounded mb-7">
                                        <!--begin::Info-->
                                        <div class="d-flex flex-stack pb-3">
                                            <!--begin::Info-->
                                            <div class="d-flex">
                                                <!--begin::Details-->
                                                <div class="ms-5">
                                                    <!--begin::Name-->
                                                    <div class="d-flex align-items-center">
                                                        <a
                                                            class="text-gray-900 fw-bold text-hover-primary fs-5 me-4">{{ tagBusiness.name }}</a>
                                                        <!--begin::Label-->
                                                        <span v-if="tagBusiness.userCount > 0"
                                                            class="badge badge-light-success d-flex align-items-center fs-8 fw-semibold">
                                                            <i class="ki-duotone ki-star fs-8 text-success me-1"></i>
                                                            Đang sử dụng
                                                        </span>
                                                        <!--end::Label-->
                                                        <!--begin::Label-->
                                                        <span v-else
                                                            class="badge badge-light-primary d-flex align-items-center fs-8 fw-semibold">
                                                            <i class="ki-duotone ki-star fs-8 text-primary me-1"></i>
                                                            Chưa sử dụng
                                                        </span>
                                                        <!--end::Label-->
                                                    </div>
                                                    <!--end::Name-->
                                                </div>
                                                <!--end::Details-->
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Info-->
                                        <!--begin::Wrapper-->
                                        <div class="p-0">
                                            <!--begin::Section-->
                                            <div class="d-flex flex-column">
                                            </div>
                                            <!--end::Section-->
                                            <!--begin::Footer-->
                                            <div class="d-flex flex-column">
                                                <!--begin::Separator-->
                                                <div class="separator separator-dashed border-muted my-5"></div>
                                                <!--end::Separator-->

                                                <!--begin::Action-->
                                                <div class="d-flex flex-stack">
                                                    <!--begin::Progress-->
                                                    <div class="d-flex flex-column mw-200px">
                                                        <div class="d-flex align-items-center mb-2">
                                                            <span v-if="tagBusiness.userCount > 0" class="text-gray-700 fs-6 fw-semibold me-2">
                                                                {{ tagBusiness.userCount }}
                                                            </span>
                                                            <span v-if="tagBusiness.userCount > 0" class="text-muted fs-8">User đang sử dụng</span>
                                                            <span v-else class="text-muted fs-8">Chưa có user sử dụng</span>
                                                        </div>
                                                    </div>
                                                    <!--end::Progress-->
                                                    <!--begin::Button-->
                                                    <a @click="addTagBusinessToUser(this.currentUserIdPickBusiness,tagBusiness.businessId)" class="btn btn-sm btn-primary">Chọn</a>
                                                    <!--end::Button-->
                                                </div>
                                                <!--end::Action-->
                                            </div>
                                            <!--end::Footer-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::User-->

                                </div>
                                <!--end::Users-->
                            </div>
                            <!--end::Modal Body-->
                        </div>
                        <!--end::Modal content-->
                    </div>
                    <!--end::Modal dialog-->
                </div>

                <!--end::Modal - Pick Business-->

            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
</template>
<style scoped>
.table {
    overflow: scroll;
    height: calc(100vh - 275px);
    display: block;
}

.active__update__combobox {
    z-index: 555 !important;
}

.menu {
    left: -20px;
    top: 53px;
    position: relative;
}

td {
    text-align: start;
}

.menu-sub-dropdown {
    position: absolute;
}

.block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.loading-1 {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.min-w-125px {
    min-width: 250px !important
}

.form-check {
    display: flex;
    /* min-height: 1.5rem; */
    height: 30px;
    padding-left: 2.25rem;
    margin-bottom: 0.125rem;
    align-items: center;
}

.form-check-input {
    margin-right: 10px;
}

.fix-data-footer {
    position: sticky;
    left: 0px;
    background: #fff;
    z-index: 10;
}

.table tr#total {
    position: sticky;
    bottom: 0px;
    left: 0;
    background: #fff;
    z-index: 4;
}

.table tr#total {
    position: sticky;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import router from '@/router/router.js';
export default {
    name: "TagBusiness",
    data() {
        return {
            currentUserIdPickBusiness:0,
            isShowModalPickBusiness:false,
            cardInfoImport: "",
            isShowModalAddBatchCardInfo: false,
            listTagBusiness: [],
            onShowModalAllTagBusiness: false,
            dataUser: { language: "VN" },
            currentIndex: null,
            currentProperty: null,
            sortingAsc: null,
            sortingNumber: null,
            columns: [
                { label: this.$NOLIMITResource["VN"].UserManagement.UserId, width: 50, property: "UserId" },
                { label: this.$NOLIMITResource["VN"].UserName, width: 150, property: "Username" },
                { label: this.$NOLIMITResource["VN"].UserManagement.TagBusiness, width: 250, property: "IsAdmin" },
                { label: this.$NOLIMITResource["VN"].FullName, width: 250, property: "Name" },
            ],
            numberPerPage: [10, 50, 200, 500],
            onLoading: true,
            users: [],
            totalRecharge: 0,
            onShowMenu: false,
            currenOnshowId: 0,
            userCurrent: {},
            isShowModal: false,
            currentBalanceUser: 0,
            currentTotalMoneyUser: 0,
            isShowModalUpdateUser: false,
            changeMoney: 0,
            formMode: "",
            pageSize: 10,
            pageNumber: 1,
            totalPage: 0,
            firstPage: 0,
            inputValue: "",
            previousBalance: 0,
            newBalance: 0,
            isAdmin: true,
            isSupport: true,
            hasData: true,
            checkRow: {},
            displayRow: {},
            isShowModalPickRow: false,
            idUser: 0,
            userAll: [],
            isShowModalProvideUserManager: false,
            currentMediateAccountantUser: {},
        };
    },
    watch: {
        dataUser: {
            handler: function () {
                this.columns = [
                    { label: this.$NOLIMITResource[this.dataUser.language].UserManagement.UserId, width: 200, property: "UserId" },
                    { label: this.$NOLIMITResource[this.dataUser.language].UserName, width: 550, property: "Username" },
                    { label: this.$NOLIMITResource[this.dataUser.language].UserManagement.TagBusiness, width: 450, property: "IsAdmin" },
                    { label: this.$NOLIMITResource[this.dataUser.language].FullName, width: 550, property: "Name" },
                ];
            }
        },

        pageSize() {
            this.reDisplay();
        },
        userAll() {
            this.totalRecharge = 0;
            for (let i = 0; i < this.userAll.length; i++) {
                if (this.userAll[i].isAdmin == false) {
                    this.totalRecharge += this.userAll[i].totalMoney
                }
            }


        }

    },
    computed: {
        ...mapGetters("listModule", [
            "formatCurrency", "formatDateTime"
        ]),

        formatCurrentBalanceUserDisplay() {
            return this.formatCurrency(this.currentBalanceUser) + " VND";
        },
        formatCurrentTotalMoneyUserDisplay() {
            return this.formatCurrency(this.currentTotalMoneyUser) + " VND";
        },
        formatChangeMoneyDisplay() {
            return this.formatCurrencyValue(this.changeMoney) + " VND";
        },
    },
    created() {
        this.$apiService
            .getUserProfile()
            .then((response) => {
                if (response.data.data.language == "VN" || response.data.data.language == "EN") {
                    this.dataUser = response.data.data;
                } else {
                    this.dataUser = response.data.data;
                    this.dataUser.language = "VN";
                }
                this.idUser = response.data.data.userId;
                this.isAdmin = response.data.data.isAdmin;
                this.isSupport = response.data.data.isSupport;
                if (response.data.data.isAdmin != true && response.data.data.isSupport != true) {
                    this.isAdmin = false;
                    this.isSupport = false;
                    router.push({ name: 'ErrorNotFound' });
                }
                this.$apiService
                    .getAllUserPanigation(this.pageNumber, this.pageSize)
                    .then((res) => {
                        setTimeout(() => { this.onLoading = false }, 1000)
                        this.users = res.data.data;
                        if (res.data.data.length == 0) {
                            this.hasData = false;
                        }
                        this.totalPage = res.data.pageData.pageCount;
                        this.$apiService.getDisplayPropertiesByUserId(response.data.data.userId)
                            .then((res) => {
                                this.displayRow = res.data
                            })
                    })
                    .catch((error) => {
                        console.error("Lỗi khi lấy dữ liệu:", error);
                    });

            })
            .catch((error) => {
                console.error("Lỗi khi lấy dữ liệu:", error);
            });

        this.$apiService.getAllUserNoPaging(
        ).then((res) => {
            this.userAll = res.data.data;
        })
    },

    methods: {
        ...mapActions("listModule", ["toggleMenuFilter", "swalSuccess", "swalError", "swalWarning"]),
        toggleMenuItem(id) {
            this.onShowMenu = !this.onShowMenu;
            this.currenOnshowId = id;
        },
        openModalAllCardInfo() {
            this.$apiService.getAllTagBusiness().then((res) => {
                this.listTagBusiness = res.data.data;
            })
            this.onShowModalAllTagBusiness = true;
        },
        closeModalAllCardInfo() {
            this.onShowModalAllTagBusiness = false;
        },
        openModalAddBatchCardInfo() {
            this.isShowModalAddBatchCardInfo = true;
        },
        closeModalAddBatchCardInfo() {
            this.isShowModalAddBatchCardInfo = false;
        },
        convertStringToArray() {
            const formData = new FormData();
            formData.append('business', this.cardInfoImport);
            this.$apiService.importManyBusiness(formData)
                .then(() => {
                    this.reDisplay()
                    this.swalSuccess("Thêm nhãn dán thành công!")
                    this.closeModalAddBatchCardInfo();
                    this.closeModalAllCardInfo();
                }).catch((error) => {
                    this.swalError({ message: error.response.data.message, language: "VN" })
                })
        },
        selectPage(index) {
            this.pageNumber = index;
            if (this.totalPage >= 4) {
                if (index == this.totalPage) {
                    this.firstPage = index - 4;
                } else if (index >= 3) {
                    this.firstPage = index - 3;
                } else {
                    this.firstPage = 0;
                }
            }
            this.onEnterSearch();
        },
        reDisplay() {
            this.hasData = true;
            this.$apiService
                .getAllUserPanigation(this.pageNumber, this.pageSize)
                .then((res) => {
                    this.onLoading = true
                    setTimeout(() => { this.onLoading = false }, 1000)
                    this.users = res.data.data;
                    if (res.data.data.length == 0) {
                        this.hasData = false;
                    }
                    this.totalPage = res.data.pageData.pageCount;
                })
                .catch((error) => {
                    console.error("Lỗi khi lấy dữ liệu:", error);
                });
            this.$apiService.getAllUserNoPaging(
            ).then((res) => {
                this.userAll = res.data.data;
            })
        },
        /**
         * Chọn next trang tiếp theo trong mục phân trang
         * Author: ndanh (20/09/2023)
         */
        nextPage() {
            if (this.pageNumber != this.totalPage) {
                this.pageNumber += 1;
                if (this.totalPage >= 4) {
                    if (this.pageNumber == this.totalPage) {
                        this.firstPage = this.pageNumber - 4;
                    } else if (this.pageNumber >= 3) {
                        this.firstPage = this.pageNumber - 3;
                    } else {
                        this.firstPage = 0;
                    }
                }
            }
            this.onEnterSearch();
        },
        /**
         * Chọn prev trang trước đó trong mục phân trang
         * Author: ndanh (20/09/2023)
         */
        prevPage() {
            if (this.pageNumber != 1) {
                this.pageNumber -= 1;
                if (this.totalPage >= 4) {
                    if (this.pageNumber == this.totalPage) {
                        this.firstPage = this.pageNumber - 4;
                    } else if (this.pageNumber >= 3) {
                        this.firstPage = this.pageNumber - 3;
                    } else {
                        this.firstPage = 0;
                    }
                }
            }
            this.onEnterSearch();
        },
        onEnterSearch() {
            this.hasData = true;
            if (this.inputValue == "") {
                this.reDisplay();
            } else {
                this.$apiService
                    .getAllUserPanigationSearch(
                        this.pageNumber,
                        this.pageSize,
                        this.inputValue
                    )
                    .then((res) => {
                        this.onLoading = true
                        setTimeout(() => { this.onLoading = false }, 1000)
                        this.users = res.data.data;
                        if (res.data.data.length == 0) {
                            this.hasData = false;
                        }

                        this.totalPage = res.data.pageData.pageCount;
                    })
                    .catch((error) => {
                        console.error("Lỗi khi lấy dữ liệu:", error);
                    });
                this.$apiService.getAllUserNoPagingSearch(
                    this.inputValue
                ).then((res) => {
                    this.userAll = res.data.data;
                })
            }
        },
        onEnterSearchFirst() {
            this.hasData = true;
            if (this.inputValue == "") {
                this.reDisplay();
            } else {
                this.pageNumber = 1;
                this.firstPage = 0;
                this.$apiService
                    .getAllUserPanigationSearch(
                        this.pageNumber,
                        this.pageSize,
                        this.inputValue
                    )
                    .then((res) => {
                        this.onLoading = true
                        setTimeout(() => { this.onLoading = false }, 1000)
                        this.users = res.data.data;
                        if (res.data.data.length == 0) {
                            this.hasData = false;
                        }

                        this.totalPage = res.data.pageData.pageCount;
                    })
                    .catch((error) => {
                        console.error("Lỗi khi lấy dữ liệu:", error);
                    });
                this.$apiService.getAllUserNoPagingSearch(
                    this.inputValue
                ).then((res) => {
                    this.userAll = res.data.data;
                })
            }
        },

        choosePageSize(event) {
            this.pageSize = event.target.value;
            this.reDisplay();
        },

        startResize(event, index) {
            if (event.offsetX > event.target.offsetWidth - 10) {
                this.isResizing = true;
                this.initialX = event.clientX;
                this.initialWidth = this.columns[index].width;
                this.resizingCol = index;

                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
            }
        },
        handleMouseMove(event) {
            if (this.isResizing && this.resizingCol !== null) {
                const delta = event.clientX - this.initialX;
                const newWidth = Math.max(50, this.initialWidth + delta);
                this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
            }
        },
        handleMouseUp() {
            this.isResizing = false;
            this.initialX = 0;
            this.initialWidth = 0;
            this.resizingCol = null;

            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.handleMouseUp);
        },
        shouldDisplay(index) {
            switch (index) {
                case 1:
                    return this.displayRow.username;
                case 2:
                    return this.displayRow.roleUser;
                case 3:
                    return this.displayRow.fullnameUser;
                case 4:
                    return this.displayRow.email;
                case 5:
                    return this.displayRow.statusBanUser;
                case 6:
                    return this.displayRow.balanceUser;
                case 7:
                    return this.displayRow.totalMoneyUser;
                case 8:
                    return this.displayRow.moneyOutUser;
                case 9:
                    return this.displayRow.moneyInUser;
                case 10:
                    return this.displayRow.topupFeeUser;
                // Thêm các trường hợp khác tương ứng
                default:
                    return true; // Hiển thị mặc định nếu không có điều kiện nào khớp
            }
        },
        formatCurrencyValue(value) {
            // Chuyển đổi số thành chuỗi
            let stringValue = value.toString();

            // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
            let parts = stringValue.split('.');
            let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Nếu có phần thập phân, thêm vào chuỗi
            let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

            return result;
        },

        openModalPickBusiness(id){
            this.$apiService.getAllTagBusiness().then((res) => {
                this.listTagBusiness = res.data.data;
            })
        this.currentUserIdPickBusiness = id;
        this.isShowModalPickBusiness = true;
      },
      closeModalPickBusiness(){
        this.isShowModalPickBusiness = false;
      },
      addTagBusinessToUser(userId,businessId){
        this.$apiService.addTagBusinessToUser(userId,businessId).then(()=>{
            this.swalSuccess("Thêm nhãn doanh nghiệp thành công!")
            this.onShowMenu = !this.onShowMenu;
            this.isShowModalPickBusiness = false;
            this.reDisplay()
        })
      },
      deleteBusiness(userId){
        this.$apiService.deleteTagBusinessUser(userId).then(()=>{
            this.swalSuccess("Xoá nhãn doanh nghiệp thành công!")
            this.onShowMenu = !this.onShowMenu;
            this.reDisplay()
        })
      }
    },
};
</script>